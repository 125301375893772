import React from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { fileTypesToAcceptString } from './utils'
import { FileUploaderBase } from './FileUploaderBase'
import { FileUploadReducer, createFileUploaderReducer, createFileUploaderValidation, useFileUploader } from './useFileUploader'
import { AcceptedFileTypes } from './types'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { useTranslation } from 'react-i18next'
import { useHumanReadableFilesize } from './use-human-readable-filesize'
import * as colours from '@cbgms/components/src/order-parts/parts/stock/ColourConst'

export interface Props
  extends Partial<Pick<React.ComponentProps<typeof FileUploaderBase>, 'PaperComponent' | 'multiple' | 'accept' | 'maximumFilesize'>> {
  /**
   * This prop is invoked when files state changes, e.g. on selecting a file.
   */
  onChange?: (files: File[], callback: (files: File[]) => void) => void
  /**
   * This prop can be provided to alter behavior of state change.
   */
  reducer?: FileUploadReducer
}

export const DragAndDropFileUploader: React.FC<Props> = ({
  multiple = true,
  maximumFilesize = 5,
  accept = ['jpg', 'jpeg', 'png', 'svg'] as AcceptedFileTypes[],
  reducer = createFileUploaderReducer(createFileUploaderValidation(multiple, accept, maximumFilesize)),
  onChange
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const formatter = useHumanReadableFilesize()
  const { files, setFiles, appendFiles } = useFileUploader(reducer)

  const inputRef = React.useRef<HTMLInputElement>(null)

  function updateFiles(fileList: FileList) {
    const files = Array.from(fileList)
    appendFiles(files)
  }

  function clearInputRefValue() {
    if (inputRef.current) inputRef.current.value = ''
  }

  React.useEffect(() => {
    if (files.length === 0) clearInputRefValue()
    onChange && onChange(files, setFiles)
  }, [files])

  return (
    <Box
      sx={{ mt: 2, textAlign: 'center' }}
      onDragEnter={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onDragLeave={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onDragOver={e => {
        e.preventDefault()
        e.stopPropagation()

        e.dataTransfer.dropEffect = 'copy'
      }}
      onDrop={e => {
        e.preventDefault()
        e.stopPropagation()

        updateFiles(e.dataTransfer.files)
        e.dataTransfer.clearData()
      }}
    >
      <Box
        sx={{
          border: `2px dashed ${theme.palette.primary.main}`,
          backgroundColor: colours.bgDefault,
          padding: '20px',
          borderRadius: '8px',
          cursor: 'pointer'
        }}
      >
        <InsertDriveFileIcon color='primary' sx={{ fontSize: 50 }} />
        <Typography variant='body1' gutterBottom>
          {t('FileUploader.DragAndDrop')}
          <Button variant='text' onClick={() => inputRef.current && inputRef.current.click()} sx={{ cursor: 'pointer' }}>
            <input
              data-testid='file-upload-input'
              ref={inputRef}
              style={{ display: 'none' }}
              accept={fileTypesToAcceptString(accept)}
              multiple={multiple}
              type='file'
              onChange={event => {
                if (event.target.files && event.target.files.length > 0) {
                  updateFiles(event.target.files)
                }
              }}
            />
            {t('FileUploader.Buttons.Upload')}
          </Button>
          {t('FileUploader.DragAndDropStatement')}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          {t('FileUploader.AcceptedFormats')}
          {fileTypesToAcceptString(accept)}.&nbsp;{t('FileUploader.Max_size')}&nbsp;{formatter(maximumFilesize)}.
        </Typography>
      </Box>
    </Box>
  )
}
